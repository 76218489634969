import { createSelector } from 'reselect';
import { selectFeatures } from './userProfile';
const selectAuth = (state) => state.auth;
export const selectFirebaseUser = createSelector([selectAuth], (auth) => auth.firebaseUser);
export const selectConfigEnableSSO = createSelector([selectAuth], (auth) => auth.firebaseRemoteConfig.webEnableSSO);
export const selectConfigUseWebRTC = createSelector([selectAuth], (auth) => auth.firebaseRemoteConfig.webUseWebRTC);
export const selectConfigEnableConvoV2 = createSelector([selectAuth], (auth) => auth.firebaseRemoteConfig.enableConvoV2);
export const selectConfigEnableSolodia = createSelector([selectAuth, selectFeatures], (auth, features) => auth.firebaseRemoteConfig.webEnableSolodia || features['mono-segmentation']);
export const selectSoloDiaTimeout = createSelector([selectAuth], (auth) => auth.firebaseRemoteConfig.soloDiaTimeout);
export const selectFirebaseAuthTriggered = createSelector([selectAuth], (auth) => auth.firebaseAuthTriggered);
export const selectFirebaseAuthInitiated = createSelector([selectAuth], (auth) => auth.firebaseAuthInitiated);
export const selectIsTextToSpeechV2 = createSelector([selectAuth, selectFeatures], (auth, features) => auth.firebaseRemoteConfig.isTextToSpeechV2 || features['tts-v2']);
export const selectConfigEnableCCV2 = createSelector([selectAuth], (auth) => auth.firebaseRemoteConfig.enableCCV2 && window.__TAURI__);
