var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { selectFirebaseUser } from '../../selectors/auth';
import { selectAvaId } from '../../selectors/userProfile';
import { getTurnCredentials, guestUsers, users as avaUsers, users } from '../../services/api/ava';
import { users as saasUsers } from '../../services/api/saas';
import { tauriEventEmit } from '../../services/desktopIntegration';
import * as log from '../../utils/log';
import * as segment from '../../utils/segment';
import { startStopwatch, stopAndTrack } from '../../utils/stopwatch';
import { logoutAndClean } from '../../utils/user';
import { setTurnCredentials } from '../../utils/webrtc';
import { getDefaultRoomId } from '../../utils/ws-v1';
import { scribeDashboardWSUrl } from './scribeDashboard';
import { setV1WebsocketURL } from './v1Session';
// we don't want to await the fetchRemainingCredits call
// to not slow down starting a conversation,
// so we use this as a placeholder for "haven't fetched yet"
export const HAVENT_FETCHED_SCRIBE_CREDITS = undefined;
const initialState = {
    features: {},
    updateFeaturesLoading: false,
    updateHearingProfileLoading: false,
    userProfileFetchInitiated: false,
    userProfileFetchFinished: false,
    updateUserNameLoading: false,
    updateUserNameSuccess: false,
    scribeRemainingTime: HAVENT_FETCHED_SCRIBE_CREDITS,
    paidASRCreditTime: 0,
};
export const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState,
    reducers: {
        setFeatures(state, { payload }) {
            state.features = payload;
        },
        setPaidASRCreditTime(state, { payload }) {
            state.paidASRCreditTime = payload;
        },
        decreasePaidASRCreditTime(state) {
            const newTime = state.paidASRCreditTime - 1000;
            if (newTime > 0)
                state.paidASRCreditTime = newTime;
        },
        setScribeRemainingTime(state, { payload }) {
            state.scribeRemainingTime = payload;
        },
        decreaseScribeRemainingTime(state) {
            const remaining = state.scribeRemainingTime || 0;
            const newTime = remaining - 1000;
            if (newTime > 0)
                state.scribeRemainingTime = newTime;
        },
        clear(state) {
            Object.assign(state, initialState);
        },
        resetUpdateUserNameSuccess(state) {
            state.updateUserNameSuccess = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserProfile.fulfilled, (state, action) => {
            state.userProfileFetchInitiated = false;
            state.userProfileFetchFinished = true;
            if (!action.payload)
                return;
            state.avaUser = action.payload.user;
            state.features = action.payload.user.features;
            state.subscription = action.payload.user.subscription;
            state.parse = action.payload.user.parse;
            state.profile = action.payload.user.profile;
        })
            .addCase(fetchUserProfile.pending, (state, action) => {
            state.userProfileFetchInitiated = true;
        })
            .addCase(fetchUserProfile.rejected, (state, action) => {
            state.userProfileFetchInitiated = false;
            state.userProfileFetchFinished = true;
        })
            .addCase(updateUserName.pending, (state) => {
            state.updateUserNameLoading = true;
            state.updateUserNameSuccess = false;
        })
            .addCase(updateUserName.fulfilled, (state, action) => {
            state.updateUserNameLoading = false;
            state.updateUserNameSuccess = true;
            if (state.parse)
                state.parse.userName = action.payload.userName;
        })
            .addCase(updateUserName.rejected, (state) => {
            state.updateUserNameLoading = false;
            state.updateUserNameSuccess = false;
        })
            .addCase(updateFeatures.pending, (state) => {
            state.updateFeaturesLoading = true;
        })
            .addCase(updateFeatures.rejected, (state) => {
            state.updateFeaturesLoading = false;
        })
            .addCase(updateFeatures.fulfilled, (state, action) => {
            state.features = Object.assign(state.features, action.payload);
            state.updateFeaturesLoading = false;
        })
            .addCase(updateHearingProfile.pending, (state) => {
            state.updateHearingProfileLoading = true;
        })
            .addCase(updateHearingProfile.fulfilled, (state, action) => {
            state.updateHearingProfileLoading = false;
            if (!state.profile)
                return;
            state.profile.hearingProfile = action.payload;
        })
            .addCase(updateHearingProfile.rejected, (state, action) => {
            state.updateHearingProfileLoading = false;
        })
            .addCase(getOrganization.fulfilled, (state, action) => {
            if (!state.parse)
                return;
            state.parse.organization = action.payload;
        })
            .addCase(updateUserRole.fulfilled, (state, action) => {
            var _a;
            if (!((_a = state.profile) === null || _a === void 0 ? void 0 : _a.orgProperties))
                return;
            state.profile.orgProperties.role = action.payload;
        });
    },
});
export const userProfileReducer = userProfileSlice.reducer;
export const { clear, decreasePaidASRCreditTime, decreaseScribeRemainingTime, setPaidASRCreditTime, setScribeRemainingTime, resetUpdateUserNameSuccess, } = userProfileSlice.actions;
export const fetchUserProfile = createAsyncThunk('userProfile/fetchUserProfile', (_, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const state = (yield thunkAPI.getState());
    const dispatch = thunkAPI.dispatch;
    const firebaseUser = state.auth.firebaseUser;
    const firebaseAuthUID = firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.uid;
    if (!firebaseUser || !firebaseAuthUID)
        return;
    getTurnCredentials().then((turnCredentials) => {
        if (window.__TAURI__) {
            tauriEventEmit('webrtc-turn-credentials', {
                turnCredentials,
            });
        }
        else {
            setTurnCredentials(turnCredentials);
        }
    });
    const avaId = localStorage.getItem('avaId');
    let response;
    if (firebaseUser.isAnonymous) {
        if (!avaId) {
            // new guest user
            startStopwatch('createGuestProfile');
            response = yield guestUsers.createGuestProfile({
                roomId: firebaseUser.uid && getDefaultRoomId(firebaseUser.uid),
                firebaseAuthUID,
            });
            stopAndTrack('createGuestProfile');
            stopAndTrack('userProfile', { guest: true, new: true });
        }
        else {
            // existing guest user
            try {
                response = yield guestUsers.getGuestProfile({ avaId, firebaseAuthUID });
                stopAndTrack('userProfile', { guest: true, new: false });
            }
            catch (e) {
                // TODO: Add some tracking here.
                segment.track('Web - Login Error - Guest Profile', { error: e });
                localStorage.removeItem('avaId');
            }
        }
    }
    else {
        if (!firebaseAuthUID) {
            // This can only be thrown if there is an error in our code, never due to
            // external circumstance.
            throw new Error("Can't fetch non-anonymous user-profile without firebase user");
        }
        try {
            // Despite the name, this function cannot currently (05/16/2022) create
            // a profile. The ava-backend cannot do that at all, only saas-backend can.
            // That's why if that fails with a 400 and a custom_code of 104, we need to
            // create the profile via saas-backend, and then re-fetch it through ava-backend
            // in order to have complete data. This is slow, but only affects new users,
            // and can be cleaned up if the ava-backend ever allows user creation.
            response = yield avaUsers.getOrCreateProfile({ firebaseAuthUID });
            stopAndTrack('userProfile', { guest: false, new: false });
        }
        catch (e) {
            try {
                if (!e.response || !e.response.data || e.response.data.custom_error_code !== 104 || e.response.status !== 400) {
                    segment.track('Web - Login Error - Regular Profile', { error: e });
                    throw e;
                }
                // This is able to create a profile, but returns an incomplete set of feature flags
                // However - it often returns errors despite successfully creating the user!
                // Welcome to try-catch madness.
                try {
                    yield saasUsers.getOrCreateUser({});
                }
                catch (e) { }
                // That's why we need to refetch the profile from avaUsers.
                response = yield avaUsers.getOrCreateProfile({ firebaseAuthUID });
                stopAndTrack('userProfile', { guest: false, new: true });
            }
            catch (e) {
                if (e.toJSON && e.toJSON().message === 'Network Error') {
                    // If it's a network error - do not log out, because we might successfully
                    // reconnect eventually.
                    return;
                }
                segment.track('Web - Login Error - Regular Profile', { error: e });
                logoutAndClean();
            }
        }
    }
    const { user, wsUrl, scribeUrl } = response.data;
    dispatch(scribeDashboardWSUrl(scribeUrl));
    dispatch(setV1WebsocketURL(wsUrl));
    if (!firebaseUser.isAnonymous) {
        dispatch(getOrganization());
    }
    log.updateUserInfo({
        name: user.parse.userName,
        avaName: user.parse.avaName,
        firebaseAuthUid: user.parse.firebaseAuthUID,
        avaId: user.parse.avaId,
    });
    return { user, wsUrl, scribeUrl };
}));
export const updateUserName = createAsyncThunk('userProfile/updateUserName', (userName, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const state = (yield thunkAPI.getState());
    const avaId = (_a = state.userProfile.parse) === null || _a === void 0 ? void 0 : _a.avaId;
    const firebaseUser = state.auth.firebaseUser;
    if (!avaId || !firebaseUser)
        throw new Error('avaId missing');
    yield users.updateUserName({
        avaId,
        firebaseAuthUID: firebaseUser.uid,
        userName,
    });
    return { userName, avaId };
}));
export const updateUserRole = createAsyncThunk('userProfile/updateUserRole', (role, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    // TODO: Should this issue an API call?
    return role;
}));
export const updateFeatures = createAsyncThunk('userProfile/updateFeatures', (features, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const state = thunkAPI.getState();
    const firebaseUser = selectFirebaseUser(state);
    const avaId = selectAvaId(state);
    if (!firebaseUser || !avaId)
        throw new Error('logged out');
    yield users.updateFeatures({ avaId, firebaseAuthUID: firebaseUser.uid, features });
    return features;
}));
export const updateHearingProfile = createAsyncThunk('userProfile/updateHearingProfile', (hearingProfile, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const state = thunkAPI.getState();
    const firebaseUser = selectFirebaseUser(state);
    const avaId = selectAvaId(state);
    if (!firebaseUser || !avaId)
        throw new Error('logged out');
    yield users.updateHearingProfile({ avaId: avaId, firebaseAuthUID: firebaseUser.uid, hearingProfile });
    return hearingProfile;
}));
export const getOrganization = createAsyncThunk('userProfile/fetchOrganization', (_, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const { language } = i18n;
    const res = yield saasUsers.getUser(language);
    return res.data.organization;
}));
