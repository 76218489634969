var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import { AudioErrorType, errorToAudioError } from '../../modules/AudioV2/errors';
import { selectAudioContext, selectAudioV2Status, selectAvailableMicrophones, selectExternalMicForAvaMic, selectIncludeMicrophoneWithInternalAudio, selectSelectedMicrophone, selectWebRTCSenders, selectWebRTCTracks, } from '../../selectors/audioV2';
import { selectSncfConsentGiven } from '../../selectors/avaTranslate';
import { selectCanBeginRecording, selectCanRecord, selectIsAvaMicAvailable } from '../../selectors/combined';
import { selectCurseFilter } from '../../selectors/conversation';
import { selectLang, selectSpeechLang } from '../../selectors/legacy-conversation';
import { selectScribeTrainingAudioStream, selectScribeTrainingRequested } from '../../selectors/scribe-dashboard';
import { selectAvaId } from '../../selectors/userProfile';
import { selectV1Websocket } from '../../selectors/v1Session';
import { tauriInvoke } from '../../services/desktopIntegration';
import LocalStorage, { STORAGE_KEYS } from '../../services/localStorage';
import { isMac, isWindows } from '../../utils';
import { getSingletonWebRTCManager } from '../../utils/webrtc';
import { sendAudioParams, sendMbackendMessage, sendWebRTCTrackMetadataMessage } from '../../utils/ws-v1';
import { setSncfConsentDialogShown } from './avaTranslate';
export const AUDIO_CONSTRAINTS = {
    echoCancellation: false,
    noiseSuppression: false,
    autoGainControl: false,
};
export const INTERNAL_AUDIO_MIC_ID = 'internal';
export const INTERNAL_AUDIO_MIC_NAME = 'Ava Computer Audio';
export var RecordingStatus;
(function (RecordingStatus) {
    // Status when the recording is stopped.
    RecordingStatus["NOT_RECORDING"] = "NOT_RECORDING";
    // The recording has been requested, but it is not stable.
    // This state should soon resolve into either NOT_RECORDING or RECORDING
    RecordingStatus["PENDING"] = "PENDING";
    // Recording is in progress.
    RecordingStatus["RECORDING"] = "RECORDING";
})(RecordingStatus || (RecordingStatus = {}));
const getInitialState = () => {
    const state = {
        status: RecordingStatus.NOT_RECORDING,
        availableMicrophones: [],
        includeMicrophoneWithInternalAudio: true,
        webRTCSenders: [],
        webRTCTracks: [],
        needInternalAudioAccess: false,
        microphoneAccess: 'granted',
        volume: 0,
        allMicsSelected: [],
        recordingCancellerSubscribed: false,
    };
    if (window.isElectron) {
        // We ask electron to check the mac internal audio config
        const audioSetup = window.electronIPC.sendSyncCheckAudioSetup_UNSAFE();
        if (audioSetup) {
            state.needInternalAudioAccess = !audioSetup.internalAudio;
            state.microphoneAccess = audioSetup.micAccess;
        }
    }
    if (window.__TAURI__) {
        // Just initializing the AudioContext in some versions of WebKit makes the
        // app use additional 20% CPU.
        return state;
    }
    try {
        state.audioContext = new AudioContext();
    }
    catch (e) {
        state.error = {
            audioErrorType: AudioErrorType.FAILED_TO_INIT_AUDIO_CONTEXT,
            message: '' + e,
        };
    }
    return state;
};
export const audioV2Slice = createSlice({
    name: 'audioV2',
    initialState: getInitialState,
    reducers: {
        setError(state, { payload }) {
            state.error = payload;
        },
        setWebRTCTracks(state, { payload }) {
            state.webRTCTracks = payload;
        },
        setWebRTCSenders(state, { payload }) {
            state.webRTCSenders = payload;
        },
        setAvailableMicrophones(state, { payload }) {
            state.availableMicrophones = payload;
        },
        setSelectedMicrophone(state, { payload }) {
            state.selectedMicrophone = payload;
            state.allMicsSelected = [...new Set([...state.allMicsSelected, (payload === null || payload === void 0 ? void 0 : payload.label) || 'no microphone'])];
        },
        setIncludeMicrophoneWithInternalAudio(state, { payload }) {
            state.includeMicrophoneWithInternalAudio = payload;
        },
        setStatus(state, { payload }) {
            state.status = payload;
        },
        setupMacAudioComplete(state) {
            state.microphoneAccess = 'granted';
            state.needInternalAudioAccess = false;
        },
        setVolume(state, { payload }) {
            state.volume = payload;
        },
        setCancelVolumeMetering(state, { payload }) {
            state.cancelVolumeMetering = payload;
        },
        setWebRTCConnectionStatus(state, { payload }) {
            state.webRTCConnectionStatus = payload;
        },
        resetAllMicsSelected(state) {
            state.allMicsSelected = [];
        },
        setRecordingCancellerSubscribed(state, { payload }) {
            state.recordingCancellerSubscribed = payload;
        },
        setExternalMicForAvaMic(state, { payload }) {
            state.externalMicForAvaMic = payload;
        },
        setOverrideAudioRestart(state, { payload }) {
            state.overrideAudioRestart = payload;
        },
    },
});
export const audioV2Reducer = audioV2Slice.reducer;
export const { setIncludeMicrophoneWithInternalAudio, setVolume, setStatus, setupMacAudioComplete, resetAllMicsSelected, setWebRTCConnectionStatus, setExternalMicForAvaMic, setOverrideAudioRestart, } = audioV2Slice.actions;
const { setError, setWebRTCSenders, setWebRTCTracks, setAvailableMicrophones, setSelectedMicrophone, setCancelVolumeMetering, setRecordingCancellerSubscribed, } = audioV2Slice.actions;
export const startRecording = createAsyncThunk('audioV2/startRecording', (_, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    // TODO: Restart recording when curseFilter, lang or speechLang change
    const state = getState();
    const audioContext = selectAudioContext(state);
    const canBeginRecording = selectCanBeginRecording(state);
    const selectedMicrophone = selectSelectedMicrophone(state);
    const ws = selectV1Websocket(state);
    const webRTCManager = getSingletonWebRTCManager();
    const isScribeTraining = selectScribeTrainingRequested(state);
    const includeMicrophoneWithInternalAudio = selectIncludeMicrophoneWithInternalAudio(state);
    if (!selectSncfConsentGiven(state)) {
        // Can't begin recording if SNCF consent was not given
        dispatch(setSncfConsentDialogShown(true));
        return;
    }
    if (!canBeginRecording || !ws)
        return;
    if (!selectedMicrophone) {
        dispatch(setError({
            audioErrorType: AudioErrorType.NO_MICROPHONE_SELECTED,
        }));
        return;
    }
    dispatch(setError(undefined));
    if (window.__TAURI__) {
        yield tauriInvoke('stop_all_recording');
        yield dispatch(setStatus(RecordingStatus.PENDING));
        sendAudioParams(ws, prepareAudioParams(selectCurseFilter(state), selectLang(state), selectSpeechLang(state), selectedMicrophone));
        yield tauriInvoke('start_recording', { deviceName: selectedMicrophone.name });
        if (selectedMicrophone.isInternal) {
            const nonInternalMicrophones = selectAvailableMicrophones(state).filter((m) => !m.isInternal);
            const externalMicForAvaMic = selectExternalMicForAvaMic(state);
            if (nonInternalMicrophones.length > 0 && includeMicrophoneWithInternalAudio) {
                const defaultMic = externalMicForAvaMic || nonInternalMicrophones.find((m) => m.default) || nonInternalMicrophones[0];
                if (!externalMicForAvaMic) {
                    dispatch(setExternalMicForAvaMic(defaultMic));
                }
                yield tauriInvoke('start_recording', { deviceName: defaultMic.name });
            }
        }
        return;
    }
    if (!webRTCManager) {
        dispatch(setError({
            audioErrorType: AudioErrorType.NO_WEBRTC_MANAGER,
        }));
        return;
    }
    if (!audioContext) {
        dispatch(setError({
            audioErrorType: AudioErrorType.NO_AUDIO_CONTEXT,
        }));
        return;
    }
    yield dispatch(setStatus(RecordingStatus.PENDING));
    try {
        const streams = [];
        if (isScribeTraining) {
            const scribeTrainingAudioStream = selectScribeTrainingAudioStream(getState());
            if (!scribeTrainingAudioStream) {
                dispatch(setStatus(RecordingStatus.NOT_RECORDING));
                return;
            }
            streams.push({
                stream: scribeTrainingAudioStream,
                name: 'Scribe Training Audio',
                isInternal: false,
            });
        }
        else if (selectedMicrophone.isInternal) {
            if (isWindows) {
                streams.push(yield getWindowsInternalAudioStream());
            }
            if (window.isElectron && isMac) {
                window.electronIPC.sendActivateMacInternalAudio();
                streams.push(yield getMicrophoneStream(selectedMicrophone));
            }
            if (includeMicrophoneWithInternalAudio) {
                try {
                    const defaultMicStream = yield getDefaultMicrophoneStream();
                    if (defaultMicStream)
                        streams.push(defaultMicStream);
                }
                catch (e) {
                    // Don't do anything - we want to allow only internal audio
                    // in case getting the default microphone fails.
                }
            }
        }
        else {
            let microphoneStream;
            if (selectedMicrophone && selectedMicrophone.id) {
                microphoneStream = yield getMicrophoneStream(selectedMicrophone);
            }
            else {
                microphoneStream = yield getDefaultMicrophoneStream();
            }
            if (microphoneStream)
                streams.push(microphoneStream);
        }
        dispatch(setCancelVolumeMetering(yield setupVolumeMetering(audioContext, (volume) => {
            dispatch(setVolume(volume));
        }, streams)));
        sendAudioParams(ws, prepareAudioParams(selectCurseFilter(state), selectLang(state), selectSpeechLang(state), selectedMicrophone));
        streams.forEach((stream) => sendWebRTCTrackMetadataMessage(ws, {
            streamId: stream.stream.id,
            name: stream.name,
            isInternal: stream.isInternal,
        }));
        const tracks = [];
        const senders = [];
        streams.forEach((stream) => stream.stream.getAudioTracks().forEach((track) => {
            senders.push(webRTCManager.addTrack(track, stream.stream));
            tracks.push(track);
            track.addEventListener('ended', () => {
                dispatch(stopRecording());
            });
        }));
        dispatch(setWebRTCTracks(tracks));
        dispatch(setWebRTCSenders(senders));
        dispatch(setStatus(RecordingStatus.RECORDING));
        if (tracks.length > 0) {
            // If we have any tracks, that means that user has granted permission, which
            // means we should get full data about available mics.
            dispatch(getCurrentAvailableMics());
        }
        if (!state.audioV2.recordingCancellerSubscribed) {
            dispatch(setRecordingCancellerSubscribed(true));
            const unsubscribe = window.store.subscribe(() => {
                const state = window.store.getState();
                const canRecord = selectCanRecord(state);
                const audioStatus = selectAudioV2Status(state);
                if (!canRecord && audioStatus === RecordingStatus.RECORDING) {
                    unsubscribe();
                    dispatch(setRecordingCancellerSubscribed(false));
                    dispatch(stopRecording());
                }
            });
        }
    }
    catch (err) {
        dispatch(setStatus(RecordingStatus.NOT_RECORDING));
        if (err.audioErrorType) {
            dispatch(setError(err));
        }
        else {
            dispatch(setError(errorToAudioError(err)));
        }
    }
}));
export const stopRecording = createAsyncThunk('audioV2/stopRecording', (_, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    const state = getState();
    const selectedMicrophone = selectSelectedMicrophone(state);
    const audioStatus = selectAudioV2Status(state);
    const avaId = selectAvaId(state);
    if (audioStatus !== RecordingStatus.RECORDING && audioStatus !== RecordingStatus.PENDING)
        return;
    if (window.__TAURI__) {
        yield tauriInvoke('stop_all_recording');
        const ws = selectV1Websocket(state);
        if (ws) {
            sendMbackendMessage(ws, {
                type: 'mute',
            });
        }
        dispatch(setStatus(RecordingStatus.NOT_RECORDING));
        return;
    }
    if (window.isElectron && isMac && (selectedMicrophone === null || selectedMicrophone === void 0 ? void 0 : selectedMicrophone.isInternal)) {
        window.electronIPC.sendDeactivateMacInternalAudio();
    }
    const webRTCTracks = selectWebRTCTracks(state);
    webRTCTracks.forEach((track) => track.stop());
    const webRTCManager = getSingletonWebRTCManager();
    const webRTCSenders = selectWebRTCSenders(state);
    if (webRTCManager) {
        webRTCSenders.forEach((sender) => webRTCManager.removeSender(sender));
    }
    const cancelVolumeMetering = state.audioV2.cancelVolumeMetering;
    if (cancelVolumeMetering) {
        cancelVolumeMetering();
        dispatch(setCancelVolumeMetering(undefined));
    }
    const ws = selectV1Websocket(state);
    if (ws) {
        // If the backend is aware of our current audio stream, we need to mute it.
        // It can happen that it is not - for example after reconnects. Then we should
        // not be muting it.
        const ourAudioStream = (_c = (_b = (_a = state.scribeConversation) === null || _a === void 0 ? void 0 : _a.status) === null || _b === void 0 ? void 0 : _b.audioStreams) === null || _c === void 0 ? void 0 : _c.find((audioStream) => {
            return audioStream.avaId === avaId;
        });
        if (ourAudioStream) {
            sendMbackendMessage(ws, {
                type: 'mute',
            });
        }
    }
    dispatch(setStatus(RecordingStatus.NOT_RECORDING));
}));
// If RecordingStatus === RECORDING - restarts recording. Otherwise does not do anything.
export const maybeRestartRecording = createAsyncThunk('audioV2/maybeRestartRecording', (_, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const audioStatus = selectAudioV2Status(state);
    if (audioStatus === RecordingStatus.RECORDING) {
        yield dispatch(stopRecording());
        yield dispatch(startRecording());
    }
}));
export const prepareAudioParams = (curseFilter, translationLang, speechLang, selectedMic) => {
    const mic = selectedMic.isBluetooth ? 'BT' : 'BUILTIN';
    const translation = translationLang && translationLang !== '~' ? { target: translationLang } : undefined;
    return {
        lang: speechLang,
        pFilter: curseFilter,
        mic,
        format: 'webrtc',
        sampleRateHz: 16000,
        recordingMode: 'web',
        chunkLengthMs: 60,
        recordingStart: Date.now(),
        translation,
    };
};
const getDefaultMicrophoneStream = () => __awaiter(void 0, void 0, void 0, function* () {
    const stream = yield window.navigator.mediaDevices.getUserMedia({
        audio: Object.assign(Object.assign({}, AUDIO_CONSTRAINTS), { deviceId: { ideal: 'default' } }),
    });
    return {
        stream,
        name: getTrackNameOrThrow(stream),
        isInternal: false,
    };
});
const getMicrophoneStream = (mic) => __awaiter(void 0, void 0, void 0, function* () {
    // TODO: For some reason this was not working on Windows.
    const stream = yield window.navigator.mediaDevices.getUserMedia({
        audio: Object.assign(Object.assign({}, AUDIO_CONSTRAINTS), { deviceId: { exact: mic.id } }),
    });
    return {
        stream,
        name: getTrackNameOrThrow(stream),
        isInternal: false,
    };
});
const getWindowsInternalAudioStream = () => __awaiter(void 0, void 0, void 0, function* () {
    let audioStream;
    const mediaDevices = window.navigator.mediaDevices;
    if (window.isElectron) {
        audioStream = yield mediaDevices.getUserMedia({
            audio: {
                // @ts-ignore
                mandatory: { chromeMediaSource: 'desktop' },
            },
            video: {
                // @ts-ignore
                mandatory: { chromeMediaSource: 'desktop' },
            },
        });
    }
    else {
        audioStream = yield mediaDevices.getDisplayMedia({
            audio: AUDIO_CONSTRAINTS,
            // Even though we do not need video, 'Audio only requests are not
            // supported' is the error given by Windows.
            video: true,
        });
        // We do not need the video tracks, so immediately stop them.
        audioStream.getVideoTracks().forEach((track) => {
            track.stop();
        });
    }
    return {
        stream: audioStream,
        name: getTrackNameOrThrow(audioStream),
        isInternal: true,
    };
});
const getTrackNameOrThrow = (stream) => {
    const tracks = stream.getAudioTracks();
    if (!tracks.length) {
        throw {
            audioErrorType: AudioErrorType.NO_AUDIO_TRACKS,
        };
    }
    return tracks[0].label;
};
export const getCurrentAvailableMics = createAsyncThunk('audioV2/getCurrentAvailableMics', (_, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    let availableMics = [];
    if (window.__TAURI__) {
        availableMics = (yield tauriInvoke('get_input_device_names'));
    }
    else {
        // Note that the following doesn't get full device info (enumerates only default devices and
        // without labels or ids) until the user grants permission - a manual process which
        // is triggered as part of recording with `navigator.mediaDevices.getUserMedia()` and
        // `navigator.mediaDevices.getDisplayMedia()` (or by the user changing browser settings,
        // which needs to happen if they denied access at one point. We nudge them in the right
        // direction with <MicrophoneDenied /> modal).
        const devices = yield navigator.mediaDevices.enumerateDevices();
        const isDeviceAudioInput = (device) => {
            return device.kind === 'audioinput';
        };
        // The list of devices may contain duplicates for 'default' device. Google Chrome will
        // contain duplicates, but other browsers might not.
        //
        // For example, we might have:
        //
        // * Default - MacBook Pro Microphone (Built-in)
        // * MacBook Pro Microphone (Built-in)
        //
        // We want to only show one to the user, so the 'default' will be filtered out.
        const defaultMic = devices.find((device) => isDeviceAudioInput(device) && device.deviceId === 'default');
        availableMics = devices
            .filter((device) => isDeviceAudioInput(device) &&
            device.deviceId !== 'default' &&
            // We filter out internal mic on non-electron Mac
            (!isMac || window.isElectron || !device.label.startsWith(INTERNAL_AUDIO_MIC_NAME)))
            .map((device) => ({
            id: device.deviceId,
            label: device.label,
            name: device.label,
            default: defaultMic ? device.groupId === defaultMic.groupId : false,
            isInternal: device.label.startsWith(INTERNAL_AUDIO_MIC_NAME),
            isBluetooth: device.label.toLowerCase().includes('bluetooth'),
        }));
        if (isWindows && selectIsAvaMicAvailable(state)) {
            availableMics.unshift({
                id: INTERNAL_AUDIO_MIC_ID,
                label: INTERNAL_AUDIO_MIC_NAME,
                name: INTERNAL_AUDIO_MIC_NAME,
                default: false,
                isInternal: true,
                isBluetooth: false,
            });
        }
    }
    // filter Ava Mic out if it's not available
    if (!selectIsAvaMicAvailable(state)) {
        availableMics = availableMics.filter((mic) => !mic.isInternal);
    }
    dispatch(setAvailableMicrophones(availableMics));
    if (!availableMics.length) {
        dispatch(setError({
            audioErrorType: AudioErrorType.NO_AUDIO_INPUTS,
        }));
    }
    dispatch(refreshSelectedMic());
}));
// Microphone is selected in the following priority:
//  1. Currently selected mic from Redux state (by ID, if exists in availableMics)
//  2. Saved mic from local storage if user manually selects a mic
//  3. Ava mic if available (Desktop app or Windows browser (except Firefox))
//  4. Default mic, Google Chrome will label this while other browsers might not
//  5. First available mic
const refreshSelectedMic = createAsyncThunk('audioV2/refreshSelectedMic', (_, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const availableMics = selectAvailableMicrophones(state);
    if (!availableMics.length) {
        dispatch(selectMicrophone(undefined));
        return;
    }
    const selectedMicrophone = selectSelectedMicrophone(state);
    if (selectedMicrophone && availableMics.find((mic) => mic.id === selectedMicrophone.id)) {
        // Currently selected microphone is still available, so we don't need to change it
        return;
    }
    const getMicrophoneByIdIfExist = (availableMics, microphoneId) => {
        return microphoneId && availableMics.find((mic) => mic.id === microphoneId);
    };
    const savedMic = getMicrophoneByIdIfExist(availableMics, LocalStorage.get(STORAGE_KEYS.SELECTED_MIC_ID));
    const avaMic = availableMics.find((mic) => mic.isInternal);
    const defaultMic = availableMics.find((mic) => mic.default);
    const firstAvailableMic = availableMics[0];
    yield dispatch(selectMicrophone(savedMic || avaMic || defaultMic || firstAvailableMic));
}));
export const selectMicrophone = createAsyncThunk('audioV2/selectMicrophone', (microphone, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const selectedMicrophone = selectSelectedMicrophone(state);
    if ((selectedMicrophone === null || selectedMicrophone === void 0 ? void 0 : selectedMicrophone.id) === (microphone === null || microphone === void 0 ? void 0 : microphone.id)) {
        return;
    }
    if (!microphone) {
        dispatch(setOverrideAudioRestart(true));
        yield dispatch(setSelectedMicrophone(microphone));
        dispatch(stopRecording());
        return;
    }
    LocalStorage.set(STORAGE_KEYS.SELECTED_MIC_ID, microphone.id);
    if (selectedMicrophone && !selectedMicrophone.id) {
        // If the previously selected microphone was empty, and the new one is
        // the default one - it means we should not restart recording, and only
        // switch the underlying selected microphone.
        yield dispatch(setSelectedMicrophone(microphone));
        return;
    }
    yield dispatch(setSelectedMicrophone(microphone));
    yield dispatch(maybeRestartRecording());
}));
const setupVolumeMetering = (audioContext, setVolume, streams) => __awaiter(void 0, void 0, void 0, function* () {
    const moduleUrl = new URL('/vumeter-processor.js', window.location.href);
    yield audioContext.audioWorklet.addModule(moduleUrl.href);
    // Volume metering is needed to display volume levels
    // to the user (shadow on the mic button).
    //
    // Because it is loaded as a separate script/file, it can happen
    // that audioContext fails to fetch it. For example, if recording
    // is requested in offline mode, the vumeter-processor.js will
    // fail to load in case it hasn't been cached yet.
    //
    // To avoid showing the user red error banner in such cases,
    // we wrap this setup in a try .. catch block.
    let volumeMeterWorkletNode;
    let volumeInterval;
    try {
        volumeMeterWorkletNode = new AudioWorkletNode(audioContext, 'vumeter');
        const volumeHandler = () => {
            let volumeAverage = 0;
            let volumeCount = 0;
            let prevVolumeAverage = 0;
            volumeInterval = setInterval(() => {
                if (Math.abs(prevVolumeAverage - volumeAverage) > 1) {
                    setVolume(volumeAverage);
                    prevVolumeAverage = volumeAverage;
                }
                volumeCount = 0;
                volumeAverage = 0;
            }, 250);
            return (event) => {
                const eventVolume = event.data.volume || 0;
                const newVolumeSum = volumeAverage * volumeCount + eventVolume * 100;
                volumeCount += 1;
                volumeAverage = newVolumeSum / volumeCount;
            };
        };
        volumeMeterWorkletNode.port.onmessage = volumeHandler();
    }
    catch (err) {
        Sentry.captureException(err);
    }
    const sources = streams.map((stream) => {
        const streamSource = audioContext.createMediaStreamSource(stream.stream);
        streamSource.connect(volumeMeterWorkletNode);
        return streamSource;
    });
    if (audioContext.state === 'suspended') {
        yield audioContext.resume();
    }
    return () => {
        sources.forEach((source) => {
            source.disconnect();
        });
        volumeMeterWorkletNode.disconnect();
        clearInterval(volumeInterval);
    };
});
