import { createSlice } from '@reduxjs/toolkit';
import { updateIsInConversation } from './conversation';
const initialState = {
    conversationStatus: {},
    isOpen: false,
    rate: undefined,
    rateHover: undefined,
    hostName: '',
};
export const rateConversationSlice = createSlice({
    name: 'rateConversation',
    initialState,
    reducers: {
        clearConversationStatus(state) {
            state.conversationStatus = {};
        },
        setConversationStatus(state, { payload }) {
            state.conversationStatus = payload;
        },
        setRateConversationOpen(state, { payload }) {
            state.isOpen = payload;
        },
        setRate(state, { payload }) {
            state.rate = payload;
        },
        setRateHover(state, { payload }) {
            state.rateHover = payload;
        },
        setHostName(state, { payload }) {
            state.hostName = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateIsInConversation.fulfilled, (state, { meta: { arg } }) => {
            if (arg) {
                state.isOpen = false;
            }
        });
    },
});
export const rateConversationReducer = rateConversationSlice.reducer;
export const { clearConversationStatus, setConversationStatus, setRateConversationOpen, setRate, setRateHover, setHostName, } = rateConversationSlice.actions;
