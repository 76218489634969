import React, { lazy } from 'react';
// This component loads 3 HUGE different components. They are split between different chunks.
// They need to maintain the same chunk name hint for webppack between here and routes/index.tsx.
// If those names get out of sync I am not sure of the consequences.
import Loading from '../../components/Loading';
const Default = lazy(() => import(/* webpackChunkName: "default" */ '../../Layouts/Default'));
import { selectConfigEnableConvoV2 } from '../../selectors/auth';
import { selectFeature } from '../../selectors/userProfile';
import { useAppSelector } from '../../store/store';
const ConvoV2ConversationPage = lazy(() => import(/* webpackChunkName: "convoV2" */ '../../modules/convoV2/ConvoV2ConversationPage'));
const ConversationPage = lazy(() => import(/* webpackChunkName: "convoV1" */ '../../views/conversation/ConversationPage/ConversationPage'));
export const ConvoV2ConversationPageSwitch = React.memo(() => {
    const hasFlagInUserProfile = useAppSelector(selectFeature('convo-v2'));
    const hasFlagInFirebase = useAppSelector(selectConfigEnableConvoV2);
    const isScribe = useAppSelector(selectFeature('scribe'));
    // Scribes always get ConvoV1 until we implement scribing in ConvoV2
    if (!isScribe) {
        if (hasFlagInUserProfile || hasFlagInFirebase) {
            return React.createElement(ConvoV2ConversationPage, null);
        }
        if (hasFlagInFirebase === undefined) {
            return React.createElement(Loading, null);
        }
    }
    return (React.createElement(Default, null,
        React.createElement(ConversationPage, null)));
});
