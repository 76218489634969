import i18n from 'i18next';
import { call, put, select } from 'redux-saga/effects';
import { deleteLicence as deleteAction, getPlans, newLicenceFailure, newLicenceSuccess, newTrial, updateLicence as updateAction, } from '../../actions/account/licence';
import { fetchUserInfos } from '../../actions/account/user';
import { getAnalyticsInstance } from '../../firebase';
import * as avaApi from '../../services/api/ava';
import { getStripePlan, licences } from '../../services/api/saas';
import { triggerOrgTrial } from '../../services/api/subscription';
import LocalStorage, { STORAGE_KEYS } from '../../services/localStorage';
import * as segment from '../../utils/segment';
import { getPlanType } from '../../utils/status';
const getLicence = (state, id) => state.account.licence.licences ? state.account.licence.licences.find((el) => el.id === id) : null;
const getOffer = (state, id) => state.account.licence.offers ? state.account.licence.offers.find((el) => el.id === id) : null;
const getPlan = (state, id) => state.account.licence.plans ? state.account.licence.plans.find((el) => el.id === id) : null;
export function* startTrial() {
    try {
        const role = window.localStorage.getItem('account_role');
        const { firebaseAuthUID, avaId } = yield select((state) => {
            var _a, _b;
            return ({
                firebaseAuthUID: (_a = state.auth.firebaseUser) === null || _a === void 0 ? void 0 : _a.uid,
                avaId: (_b = state.userProfile.parse) === null || _b === void 0 ? void 0 : _b.avaId,
            });
        });
        //@ts-ignore
        yield call(avaApi.users.updateRole, {
            avaId: window.localStorage.getItem('avaId') || '',
            firebaseAuthUID: firebaseAuthUID,
            role: role,
        });
        yield call(triggerOrgTrial, avaId, firebaseAuthUID);
        if (role === 'admin') {
            yield put(newTrial.success());
            //window.browserHistory.push('/account/dashboard');
            //@ts-ignore
            window.navigate('/account/dashboard');
        }
        else {
            yield window.open('/', '_self');
        }
    }
    catch (error) {
        yield put(newTrial.failure(error));
    }
}
export function* updateLicence({ payload }) {
    var _a;
    try {
        const licence = yield select(getLicence, payload.id);
        const { language } = i18n;
        const data = {
            id: payload.id,
            licence,
            recipient: { email: payload.email },
            language,
        };
        const res = yield call(licences.updateLicence, data);
        const offer = yield select(getOffer, res.data.offerId);
        if (offer) {
            const period = (offer === null || offer === void 0 ? void 0 : offer.interval) && `${offer.interval}ly`;
            yield call(segment.track, 'License Assigned', {
                'Assignee Email': res.data.recipient.email,
                'Assignee License Period': period.charAt(0).toUpperCase() + period.slice(1),
                'Assignee License Type': (_a = offer === null || offer === void 0 ? void 0 : offer.metadata) === null || _a === void 0 ? void 0 : _a.category,
            });
        }
        yield put(updateAction.success(res.data));
        yield put(fetchUserInfos.request());
    }
    catch (err) {
        yield put(
        //@ts-ignore
        updateAction.failure({
            licenceId: payload.id,
            response: err.response,
        }));
    }
}
export function* postLicence({ payload }) {
    var _a;
    try {
        const { language } = i18n;
        const trial = window.localStorage.getItem('account_trial') || 15;
        const plan = yield select(getPlan, payload.planId);
        const period = (plan === null || plan === void 0 ? void 0 : plan.interval) && `${plan.interval}ly`;
        const data = {
            licence: payload,
            language,
            trial,
        };
        const res = yield call(licences.createLicence, data);
        yield put(newLicenceSuccess(res.data.licences));
        yield call(segment.track, 'License Purchased', {
            'License Count': payload.quantity,
            'License Period': period.charAt(0).toUpperCase() + period.slice(1),
            'License Total Payment': (plan.amount / 100) * payload.quantity,
            'License Type': (_a = plan === null || plan === void 0 ? void 0 : plan.metadata) === null || _a === void 0 ? void 0 : _a.category,
        });
        //@ts-ignore
        window.navigate('/account/payment-succeed');
    }
    catch (err) {
        yield put(newLicenceFailure(err.response.data));
    }
}
export function* fetchPlan({ payload }) {
    try {
        const { language } = i18n;
        const { id } = payload;
        const res = yield call(getStripePlan, { id, language });
        yield put(getPlans.success(res.data));
    }
    catch (err) {
        yield put(getPlans.failure(err.response));
    }
}
export function* deleteLicense({ payload }) {
    try {
        const { language } = i18n;
        const data = {
            licence: payload,
            language,
        };
        //@ts-ignore
        const res = yield call(licences.deleteLicence, data);
        yield put(deleteAction.success(Object.assign(Object.assign({}, payload), { res })));
    }
    catch (err) {
        yield put(deleteAction.failure(err.response));
    }
}
export function* openCancelLicenceTypeform() {
    const { email, org, subscription } = yield select((state) => ({
        email: state.account.user.user.email,
        org: state.account.user.user.organization,
        subscription: state.userProfile.subscription,
    }));
    const language = (i18n.language || 'en').split('-')[0];
    const url = `https://ava-me.typeform.com/to/${language === 'fr' ? 'r93HRx' : 'XM4hzr'}?mkt=${org.type}&email=${email}&orgname=${org.name}&med=acc#userid=${LocalStorage.get(STORAGE_KEYS.AVA_ID) || ''}`;
    getAnalyticsInstance().logEvent('app_submitted_typeform', {
        channel: 'inbound',
        source: 'cancel-license',
        market: org.type,
        organization_name: org.name,
        plan: getPlanType(subscription),
    });
    window.open(url, '_blank');
}
