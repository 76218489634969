import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SnackbarContent } from 'notistack';
import React, { useCallback } from 'react';
import { ConvoV2CloseIcon } from '../convoV2/ConvoV2CloseIcon';
import { ConvoV2NotificationContent } from '../convoV2/ConvoV2NotificationContent';
const useStyles = makeStyles(() => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    button: {
        padding: 0,
    },
}));
const ConvoV2Notification = React.forwardRef(({ icon, message, closeSnackbar, id, isDesktop }, ref) => {
    const classes = useStyles();
    const handleClick = useCallback(() => {
        if (closeSnackbar && id) {
            return closeSnackbar(id);
        }
    }, [closeSnackbar, id]);
    return (React.createElement(SnackbarContent, { className: classes.wrapper, ref: ref },
        React.createElement(ConvoV2NotificationContent, { desktop: !!isDesktop },
            icon,
            message,
            React.createElement(IconButton, { className: classes.button, onClick: handleClick },
                React.createElement(ConvoV2CloseIcon, { desktop: true })))));
});
export default ConvoV2Notification;
